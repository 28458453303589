<script setup lang="ts">
import type { Promotion } from "@/types";

const { open } = useNlcModals();
const loginGuard = useLoginGuard();

withDefaults(
	defineProps<{
		type: Promotion["type"];
		title?: string;
		image?: string;
		buttonInfo?: boolean;
		buttonTid?: string | null;
		buttonName?: string;
		buttonIcon?: string;
		buttonDisabled?: boolean;
	}>(),
	{ buttonTid: null }
);

const emit = defineEmits<{ (event: "clickInfo"): void; (event: "clickCard"): void }>();

const handleClick = () => {
	loginGuard({
		success: () => {
			emit("clickCard");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 498,
			height: 630,
			src: type === 'dailyWheel' ? '' : image,
			format: 'avif',
			alt: `card-${type}`,
			loading: 'lazy'
		}"
		width="332px"
		height="420px"
		:class="`card-${type}`"
		data-tid="card-img"
		@click="handleClick"
	>
		<template #top>
			<slot name="top" />
			<span v-if="buttonInfo" class="btn-info" @click.stop="emit('clickInfo')">
				<NuxtIcon name="16/info" filled />
			</span>
		</template>

		<AText :size="18" class="title" :modifiers="['bold', 'center']" as="div">{{ title }}</AText>

		<template #bottom>
			<div class="box-content flex-center">
				<slot />
			</div>
			<div class="foot flex-center">
				<slot name="bottom" />
				<AButton
					v-if="buttonName"
					variant="primary"
					:data-tid="buttonTid"
					:modifiers="['wide', buttonDisabled ? 'disabled' : '']"
					size="lg"
					class="btn"
				>
					<AText :size="16" :modifiers="['uppercase', 'black']">
						<NuxtIcon v-if="buttonIcon" :name="buttonIcon" filled />
						{{ buttonName }}
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	--a-card-default-top-padding: 0 16px;
	--a-card-default-bottom-padding: 0 16px 16px;
	--a-card-default-bottom-gap: 12px;
	--a-card-default-top-gap: 16px;

	justify-content: space-between;
	background-color: var(--neutral-15);
	gap: 12px;
}

.btn-info {
	display: inline-flex;
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 8px;
	right: 8px;
	padding: 8px;

	.nuxt-icon {
		display: inline-flex;
		font-size: 24px;

		&:deep(svg) {
			margin: 0;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

.box-content {
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.card:deep(> .bottom) {
	height: 228px;
	max-height: 228px;
	flex-shrink: 0;

	.btn {
		flex-shrink: 0;

		svg {
			font-size: 16px;
			margin: -2px 0 0;
		}
	}
}

.title {
	z-index: 1;
}

.foot {
	width: 100%;
	min-height: 50px;
}
</style>
